/*======================================================
                          Navbar
  ======================================================*/

.navbar {
  background: #fff;
  color: #0d1a26;
  position: fixed;
  top: 0;
  height: 80px;
  line-height: 80px;
  width: 100%;
  z-index: 10;
}

.navWrapper {
  text-align: center;
  width: 70%;
  margin: auto;
}

.logo a {
  font-size: 1.5em;
  height: 60px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #000;
  text-decoration: none;
}

.navbar ul {
  list-style: none;
  text-align: right;
}

.navbar .menu,
.navbar li {
  display: inline-block;
  vertical-align: middle;
}

.navbar li a {
  color: #0d1a26;
  display: block;
  font-size: 0.8em;
  height: 60px;
  letter-spacing: 1px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0 30px;
  padding: 0 4px;
}

.navbar li a:hover {
  color: #1c79b8;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
}

.navbar .logo, .navbar .logo a,
.navbar li a {
  display: inline-block;
  position: relative;
  color: #060606;
}

.navbar .logo a:after,
.navbar li a:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transform-origin: bottom left;
  transition: transform 0.5s ease-out;
}

.navbar .logo a:hover:after,
.navbar li a:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@media (max-width: 1080px) {
  .navWrapper {
    width: 95%;
  }
}

@media (max-width: 800px) {
  .navbar .menu {
    display: none;
  }

  .hamburgerMenu {
    display: inline-block;
    padding-left:30px;
  }
  .hamburgerMenu:hover {
    cursor:pointer;
  }
}

@media (min-width: 801px) {
  .hamburgerMenu {
    display:none;
  }
  /* hide mobilemenu if it's left open when increasing from mobile width */
  .mobileMenu {
    display:none;
  }
}

.mobileMenu {
  padding-top: 90px;
  list-style-type:none;
}

.mobileMenu li a{
  font-size: 0.8em;
  height: 60px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #0d1a26;
  text-decoration: none;
  display:block;
  margin: 0 30px;
  padding: 0 4px;
  text-align: center;
}
/* Remove the underlines on click on mobile i think, looks kinda weird. */
